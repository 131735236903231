<template>
  <v-row justify="start">
    <v-dialog v-model="editExperiences" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Experiences</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2">
          <v-container>
            <!-- nama perusahaan -->
            <v-row>
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Nama Perusahaan</p>
                <v-text-field
                  placeholder="Ketik Disini"
                  v-model="dataExperiences.namaPerusahaan"
                  required
                  dense
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- jabatan -->
            <v-row>
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Posisi</p>
                <v-text-field
                  placeholder="Ketik Disini"
                  v-model="dataExperiences.posisiJabatan"
                  required
                  dense
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!--periodes -->
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Periode</p>
                <v-text-field
                  placeholder="Ketik Disini"
                  required
                  dense
                  solo
                  hide-details="auto"
                  v-model="dataExperiences.periode"
                ></v-text-field>
              </v-col>
            </v-row>
            <Waiting v-if="loading" />
            <notifications
              group="validate"
              class="mt-2"
              position="top center"
            />
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            outlined
            rounded
            @click="updateData"
            v-if="!loading"
            >Save</v-btn
          >
          <v-btn
            color="red"
            outlined
            rounded
            @click="deleteData(dataExperiences.id)"
            v-if="!loading"
            >delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Waiting from "../../../_base/Modal/waiting.vue";
import Swal from "sweetalert2";

export default {
  name: "editExperiences",

  props: ["editExperiences", "dataExperiences"],
  components: {
    Waiting
  },
  computed: {
    ...mapState({
      env: state => state.API_URL,
      id_user: state => state.id
    })
  },
  data() {
    return {
      company: "",
      periode: null,
      position: "",
      loading: false
    };
  },

  methods: {
    updateData() {
      let data = new FormData();
      this.loading = true;
      data.append("nama_perusahaan", this.dataExperiences.namaPerusahaan);
      data.append("posisi_jabatan", this.dataExperiences.posisiJabatan);
      data.append("periode", this.dataExperiences.periode);
      let params = {
        id: this.dataExperiences.id,
        data: data
      };
      this.$store
        .dispatch("psycholog/updateExperiences", params)
        .then(data => {
          console.log(data);
          this.$emit("refetch");
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          this.loading = false;
          this.alert("error", `${err.message}`);
        });
    },

    deleteData(id) {
      this.$store
        .dispatch("psycholog/deleteExperiences", { id: id })
        .then(data => {
          this.$emit("refetch");
          this.$emit("close");
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        });
    },
    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
}
.bg {
  background: #ffff;
}
</style>
