<template>
  <div class="_bg-default _80w _100vh">
    <div style="margin-top: 110px">
      <div class="px-3">
        <v-alert text type="warning" class="my-2" v-if="isVerified == null">
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
              <p class="pa-0 ma-0">Aktifkan menu konseling</p>
            </div>
            <v-btn color="purple" @click="dialogVerified = true"
              >Aktifkan</v-btn
            >
          </div>
        </v-alert>
        <div v-else>
          <v-alert
            text
            type="warning"
            class="my-2"
            v-if="isVerified.status == 0"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <p class="pa-0 ma-0">
                  Permintaan request menu konseling Anda sedang di proses
                </p>
              </div>
            </div>
          </v-alert>
        </div>
      </div>
      <div class="__profile">
        <div class="_60-w px-3" v-if="profile">
          <!-- INTRO -->
          <v-card class="radius-card">
            <v-img src="../../assets/img/meptest.jpeg" height="120px"> </v-img>
            <div class="pa-3 d-flex justify-space-between">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-avatar color="white" size="150" class="min-img">
                    <v-img :src="avatar" class="img-fit" alt="asd">
                      <v-fade-transition>
                        <v-overlay v-if="hover" absolute color="#036358">
                          <v-btn icon @click="dialogCrop = true"
                            ><v-icon>mdi-camera</v-icon></v-btn
                          >
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </template>
              </v-hover>
              <v-btn
                icon
                depressed
                small
                outlined
                color="cyan"
                content="Edit Profile"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 150,
                }"
                v-if="!updateIntro"
                @click="updateIntro = true"
              >
                <v-icon dark small> mdi-pencil </v-icon>
              </v-btn>
              <v-btn
                icon
                depressed
                small
                outlined
                color="red"
                content="Edit Profile"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 150,
                }"
                v-if="updateIntro"
                @click="updateIntro = false"
              >
                <v-icon dark small> mdi-close </v-icon>
              </v-btn>
            </div>
            <div v-if="!updateIntro">
              <div class="px-3 d-flex justify-space-between">
                <div>
                  <div class="d-flex align-center">
                    <h3 class="mr-2">{{ profile.nama_lengkap }}</h3>
                    <v-icon
                      content="Terverifikasi untuk melakukan konseling"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                        theme: 'light',
                        maxWidth: 150,
                      }"
                      color="blue"
                      v-if="isVerified && isVerified.status == 1"
                      >mdi-check-decagram-outline</v-icon
                    >
                  </div>
                  <small class="grey--text">{{ profile.member_id }}</small>
                  <section v-if="field">
                    <p>{{ field.nama }} di {{ location1.nama_kota }}</p>
                  </section>
                  <section v-else>
                    <p>Tambahkan bidang</p>
                  </section>
                </div>

                <v-btn @click="getCV" dark color="purple"><b>View CV</b></v-btn>
              </div>
              <div class="pa-2">
                <v-row>
                  <v-col cols="12" md="5">
                    <v-col cols="12" md="12" class="py-0 grey--text"
                      ><v-icon>mdi-cellphone</v-icon> Phone</v-col
                    >
                    <v-col cols="12" md="12" class="py-0">{{
                      profile.no_hp
                    }}</v-col>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-col cols="12" md="12" class="py-0 grey--text"
                      ><v-icon>mdi-email</v-icon> E-mail</v-col
                    >
                    <v-col cols="12" md="12" class="py-0">{{
                      profile.email
                    }}</v-col>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="5">
                    <v-col cols="12" md="12" class="py-0 grey--text"
                      ><v-icon>mdi-map-marker</v-icon> City</v-col
                    >
                    <v-col cols="12" md="12" class="py-0"
                      >{{ location1.nama_kota }},
                      {{ location1.data_provinsi.nama_provinsi }}
                    </v-col>
                  </v-col>
                  <v-col cols="7" md="7">
                    <v-col cols="12" md="12" class="py-0 grey--text"
                      ><v-icon>mdi-map-marker-plus</v-icon> Additional
                      City</v-col
                    >
                    <v-col cols="12" md="12" class="py-0">
                      <div v-if="location2">
                        {{ location2.nama_kota }},
                        {{ location2.data_provinsi.nama_provinsi }}
                      </div>
                      <div v-else>-</div>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-col cols="12" md="12" class="py-0 grey--text"
                      ><v-icon>mdi-gender-male-female</v-icon> Gender</v-col
                    >
                    <v-col cols="12" md="12" class="py-0">{{
                      profile.jenis_kelamin
                    }}</v-col>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-col cols="12" md="12" class="py-0 grey--text"
                      ><v-icon>mdi-account-tie</v-icon> Religion</v-col
                    >
                    <v-col cols="12" md="12" class="py-0">{{
                      profile.agama
                    }}</v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-col cols="12" md="12" class="py-0 grey--text"
                      ><v-icon>mdi-link</v-icon> Website</v-col
                    >
                    <v-col cols="12" md="12" class="py-0">
                      <a
                        target="_blank"
                        :href="
                          profile.website
                            ? 'https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab'
                            : null
                        "
                        >{{ profile.website ? profile.website : "-" }}</a
                      ></v-col
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="updateIntro">
              <FormIntro
                v-bind:location1="location1"
                v-bind:location2="location2"
                @close="closeDialog(1)"
                @refetch="fetch"
              />
            </div>
          </v-card>

          <!-- Biro -->
          <!-- <v-card class="radius-card mt-3 pa-3">
            <div class="mb-2 d-flex justify-space-between">
              <h3>Biro</h3>
              <v-btn
                icon
                depressed
                outlined
                small
                color="cyan"
                content="Edit Biro"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 150,
                }"
                @click="dialogBiro = true"
              >
                <v-icon dark small> mdi-pencil </v-icon>
              </v-btn>
            </div>
            <div>
              <v-row>
                <v-col cols="12" md="5">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-account-circle-outline</v-icon> Name</v-col
                  >
                  <v-col cols="12" md="12" class="py-0">{{
                    profile.biro ? profile.biro : "-"
                  }}</v-col>
                </v-col>
                <v-col cols="12" md="7">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-map-marker</v-icon> Address</v-col
                  >
                  <v-col cols="12" md="12" class="py-0"
                    >{{ profile.biro_alamat ? profile.biro_alamat : "-" }}
                  </v-col>
                </v-col>
              </v-row>
            </div>
          </v-card> -->

          <!-- LAYANAN -->
          <v-card class="radius-card mt-3 pa-3">
            <div class="mb-2 d-flex justify-space-between">
              <h3>Services</h3>
              <v-btn
                icon
                depressed
                outlined
                small
                color="cyan"
                content="Edit Layanan"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 150,
                }"
                @click="dialogService = true"
              >
                <v-icon dark small> mdi-pencil </v-icon>
              </v-btn>
            </div>
            <div v-if="profile.layanan == 'online'">
              <div class="d-flex align-center">
                <v-icon color="green" class="mr-3">mdi-circle</v-icon>
                <h3 class="text-capitalize">
                  {{ profile.layanan }}
                </h3>
              </div>
              <!-- <p class="ma-0">Membuka layanan konsultasi secara daring</p> -->
              <v-row class="mt-2">
                <v-col cols="12" md="12">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-cellphone</v-icon> Contact and
                    Registration</v-col
                  >
                  <v-col cols="12" md="12" class="py-0">{{
                    profile.no_hp_praktek ? profile.no_hp_praktek : "-"
                  }}</v-col>
                </v-col>
              </v-row>
            </div>
            <div v-if="profile.layanan == 'offline'">
              <div class="d-flex align-center">
                <v-icon class="mr-3">mdi-circle</v-icon>
                <h3 class="text-capitalize">
                  {{ profile.layanan }}
                </h3>
              </div>
              <!-- <p class="ma-0">Membuka layanan konsultasi secara tatap muka</p> -->
              <v-row class="mt-2">
                <v-col cols="12" md="12">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-map-marker</v-icon> Address</v-col
                  >
                  <v-col cols="12" md="12" class="py-0"
                    >{{ profile.alamat_praktek ? profile.alamat_praktek : "-" }}
                  </v-col>
                </v-col>
              </v-row>
            </div>
            <div v-if="profile.layanan == 'all'">
              <div class="d-flex align-center">
                <v-icon color="blue" class="mr-3">mdi-circle-slice-4</v-icon>
                <h3>Online and Offline</h3>
              </div>
              <!-- <p class="ma-0">
                Membuka layanan konsultasi secara daring dan tatap muka
              </p> -->
              <v-row class="mt-2">
                <v-col cols="12" md="7">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-map-marker</v-icon> Address</v-col
                  >
                  <v-col cols="12" md="12" class="py-0"
                    >{{ profile.alamat_praktek ? profile.alamat_praktek : "-" }}
                  </v-col>
                </v-col>
                <v-col cols="12" md="5">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-cellphone</v-icon> Contact and
                    Registration</v-col
                  >
                  <v-col cols="12" md="12" class="py-0">{{
                    profile.no_hp_praktek ? profile.no_hp_praktek : "-"
                  }}</v-col>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <!-- ABOUT -->
          <v-card class="radius-card mt-3 pa-3">
            <div class="mb-2 d-flex justify-space-between">
              <h3>About</h3>
              <v-btn
                v-if="profile.profile_singkat"
                icon
                depressed
                outlined
                small
                color="cyan"
                content="Edit Profile Singkat"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 150,
                }"
                @click="editAbout()"
              >
                <v-icon dark small> mdi-pencil </v-icon>
              </v-btn>
            </div>
            <div v-if="!profile.profile_singkat">
              <v-btn outlined color="blue" block rounded @click="editAbout()"
                >Tambahkan profile singkat</v-btn
              >
            </div>
            <article>
              <p>
                {{ profile.profile_singkat }}
              </p>
            </article>
          </v-card>

          <!-- SKILL AND COMPETENCIES -->
          <v-card class="radius-card pa-3 mt-3">
            <div class="py-3 d-flex justify-space-between">
              <h3>Skills and Competencies</h3>
              <v-btn
                icon
                small
                depressed
                outlined
                color="cyan"
                content="Add Skills and Competencies"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 150,
                }"
                @click="dialogSkill = true"
              >
                <v-icon small dark> mdi-plus </v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-wrap">
              <div v-for="item in profile.skill_kompetensi" :key="item">
                <v-chip class="ma-2" color="orange" label outlined>
                  {{ item }}
                </v-chip>
              </div>
            </div>
            <div v-if="!profile.skill_kompetensi">
              <v-btn
                outlined
                color="blue"
                block
                rounded
                @click="dialogSkill = true"
                >Tambahkan Skill dan kompetensi</v-btn
              >
            </div>
          </v-card>

          <!-- RECOMENDATION -->
          <v-card class="radius-card my-3 pa-3" v-if="recomendation">
            <div class="mb-2">
              <h3>Recomendation</h3>
            </div>
            <section class="_full-w mt-2" v-if="recomendation.length">
              <v-carousel
                v-model="slide"
                hide-delimiters
                height="auto"
                show-arrows-on-hover
              >
                <v-carousel-item v-for="item in recomendation" :key="item.id">
                  <v-card class="pa-2 white" height="100%">
                    <v-card
                      class="
                        radius-card
                        py-2
                        px-5
                        white
                        d-flex
                        flex-column
                        justify-space-between
                      "
                      height="100%"
                    >
                      <section class="black--text mb-3">
                        <v-icon color="grey">mdi-format-quote-open</v-icon>
                        <p class="ma-0">
                          {{ item.catatan }}
                        </p>
                        <div class="d-flex justify-end">
                          <v-icon color="grey">mdi-format-quote-close</v-icon>
                        </div>
                      </section>
                      <footer class="d-flex align-center justify-end">
                        <v-avatar class="mr-2">
                          <img
                            :src="
                              item.data_pemberi.photo_profile
                                ? `${env}/upload/photo_profile/${item.pemberi_rekomendasi}/${item.data_pemberi.photo_profile}`
                                : avatar
                            "
                            alt=""
                            class="img-fit"
                          />
                        </v-avatar>
                        <div class="black--text">
                          <h4 class="ma-0 pa-0 line__height">
                            {{ item.data_pemberi.nama_lengkap }}
                          </h4>
                        </div>
                      </footer>
                    </v-card>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </section>
            <div v-if="!recomendation.length">
              <b>_</b>
            </div>
          </v-card>
        </div>
        <div class="__side _10w _100vh" v-if="profile">
          <!-- HISTORY EDUCATION -->
          <v-card class="radius-card pa-3" v-if="educational">
            <div class="pa-3 d-flex justify-space-between">
              <h3>Education</h3>
              <v-btn
                icon
                depressed
                small
                outlined
                color="cyan"
                content="Add Education"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                }"
                @click="dialogEducate = true"
              >
                <v-icon dark small> mdi-plus </v-icon>
              </v-btn>
            </div>

            <div v-if="educational.data.length">
              <section
                v-for="val in educational.data"
                :key="val.id"
                class="mb-2 hover__and__pick"
                @click="updateEducation(val)"
              >
                <div>
                  <v-icon>mdi-school</v-icon> {{ val.tingkat }} di
                  {{ val.namaLembagaPendidikan }}
                </div>
                <div class="d-flex flex-column">
                  <small class="ml-7 grey--text"
                    >Tahun Lulus : {{ val.tahunLulus }}</small
                  >
                </div>
              </section>
            </div>
            <div v-else>
              <v-btn
                rounded
                color="blue"
                outlined
                block
                class="mt-3"
                @click="dialogEducate = true"
                >Tambah history Pendidikan</v-btn
              >
            </div>
          </v-card>

          <!-- EXPERIENCES -->
          <v-card class="radius-card pa-3 mt-3" v-if="experiences">
            <div class="pa-3 d-flex justify-space-between">
              <h3>Work Experiences</h3>
              <v-btn
                icon
                depressed
                small
                outlined
                color="cyan"
                content="Add Experiences"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                }"
                @click="dialogExperiences = true"
              >
                <v-icon dark small> mdi-plus </v-icon>
              </v-btn>
            </div>
            <div v-if="experiences.data.length">
              <section
                v-for="val in experiences.data"
                :key="val.id"
                class="mb-2 hover__and__pick"
                @click="updateExperiences(val)"
              >
                <div>
                  <v-icon>mdi-office-building-marker-outline</v-icon>
                  {{ val.posisiJabatan }} di
                  {{ val.namaPerusahaan }}
                </div>
                <div class="d-flex flex-column">
                  <small class="ml-7 grey--text"
                    >Periode : {{ val.periode }}</small
                  >
                </div>
              </section>
            </div>
            <div v-else>
              <v-btn
                rounded
                color="blue"
                outlined
                block
                class="mt-3"
                @click="dialogExperiences = true"
                >Tambah Experiences</v-btn
              >
            </div>
          </v-card>
          <v-card class="radius-card mt-3">
            <div>
              <v-tabs
                v-model="tab"
                class="mb-5 rounded"
                background-color="purple lighten-5"
                color="purple"
              >
                <v-tab href="#tab-1">My Application</v-tab>
                <v-tab href="#tab-2">Feeds</v-tab>
                <v-tab href="#tab-3">Forum</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" style="background: none">
                <v-tab-item value="tab-1">
                  <div v-if="application" class="pb-2">
                    <v-card
                      v-if="!application.data.length"
                      elevation="0"
                      class="d-flex justify-center"
                    >
                      <img
                        src="../../assets/img/404.svg"
                        height="100px"
                        alt="404"
                      />
                    </v-card>
                    <v-card v-else elevation="0" class="text-center">
                      <v-btn
                        depressed
                        color="blue"
                        dark
                        to="/psikolog/my-application"
                      >
                        <b>{{ application.meta.total }} Applications</b>
                      </v-btn>
                    </v-card>
                  </div>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <div v-if="myFeed" class="pb-2">
                    <v-card elevation="0" class="text-center">
                      <v-btn depressed color="blue" dark to="/psikolog/my-feed">
                        <b>{{ myFeed.data.length }} Feeds</b>
                      </v-btn>
                    </v-card>
                  </div>
                </v-tab-item>
                <v-tab-item value="tab-3">
                  <div v-if="forum" class="pb-2">
                    <v-card elevation="0" class="text-center">
                      <v-btn
                        depressed
                        color="blue"
                        dark
                        to="/psikolog/my-forum"
                      >
                        <b>{{ forum.data.length }} Threads</b>
                      </v-btn>
                    </v-card>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card>
          <div v-if="location1 && location2" class="pa-3">
            <!-- <EditIntro
          v-bind:dialogIntro="dialogIntro"
          v-bind:location1="location1"
          v-bind:location2="location2"
          @close="closeDialog(1)"
          @refetch="fetch"
        /> -->
          </div>
          <div v-if="id" class="pa-3">
            <AddEducational
              v-bind:dialogEducate="dialogEducate"
              v-bind:id="id"
              @refetch="fetchEducation"
              @close="closeDialog(3)"
            />
          </div>
          <div class="pa-3">
            <EditAbout
              v-bind:dialogAbout="dialogAbout"
              @close="closeDialog(2)"
              v-bind:dataAbout="dataAbout"
              @refetch="fetch"
            />
            <AddExperiences
              v-bind:dialogExperiences="dialogExperiences"
              @refetch="fetchExperiences"
              @close="closeDialog(4)"
            />
            <UpdateExperiences
              v-bind:editExperiences="editExperiences"
              v-bind:dataExperiences="dataExperiences"
              @close="closeDialog(5)"
              @refetch="fetchExperiences"
              v-if="dataExperiences"
            />
            <PreviewCv
              v-bind:dialogCV="dialogCV"
              v-bind:profile="dataCV"
              @close="closeDialog(6)"
            />
            <AddSkills
              v-bind:dialogSkill="dialogSkill"
              @close="closeDialog(7)"
              @refetch="fetch"
            />
            <EditService
              v-bind:dialogService="dialogService"
              @close="closeDialog(8)"
            />
            <EditEducation
              v-bind:editEducation="editEducation"
              v-bind:dataEducation="dataEducation"
              @close="closeDialog(9)"
              @refetch="fetchEducation"
            />
            <CropPhoto
              v-bind:dialogCrop="dialogCrop"
              @close="closeDialog(10)"
              @refetch="fetch"
            />
            <EditBiro v-bind:dialogBiro="dialogBiro" @close="closeDialog(11)" />
            <CheckStatusVerified
              v-bind:dialogVerified="dialogVerified"
              @close="dialogVerified = false"
              @checking="checkReq"
            />
            <Waiting v-if="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Waiting from "../../components/_base/Modal/waiting.vue";
// import EditIntro from "../../components/Psycholog/Modal/Personal/editIntro";
import EditAbout from "../../components/Psycholog/Modal/Personal/editAbout";
import EditService from "../../components/Psycholog/Modal/Personal/editService";
import AddEducational from "../../components/Psycholog/Modal/Personal/addEducational.vue";
import AddExperiences from "../../components/Psycholog/Modal/Personal/addExperiences";
import UpdateExperiences from "../../components/Psycholog/Modal/Personal/updateExperiences";
import PreviewCv from "../../components/Psycholog/Modal/Personal/previewCv.vue";
import AddSkills from "../../components/Psycholog/Modal/Personal/addSkills.vue";
import EditEducation from "../../components/Psycholog/Modal/Personal/editEducational";
import CropPhoto from "../../components/Psycholog/Modal/cropPhoto.vue";
import FormIntro from "../../components/Psycholog/Modal/Personal/formIntro.vue";
import CheckStatusVerified from "../BATCH3/Counseling/psycholog/checkStatusVerified.vue";
import EditBiro from "../../components/Psycholog/Modal/Personal/editBiro.vue";

export default {
  name: "Personal",
  computed: {
    ...mapState({
      id: (state) => state.id,
      env: (state) => state.API_URL,
      location1: (state) => state.psycholog.location,
      myFeed: (state) => state.feeds.getFeeds,
      field: (state) => state.psycholog.field,
      profile: (state) => state.psycholog.myProfile,
      location2: (state) => state.psycholog.additionalLocation,
      university: (state) => state.psycholog.university,
      educational: (state) => state.psycholog.educational,
      experiences: (state) => state.psycholog.experiences,
      user_id: (state) => state.id,
      recomendation: (state) => state.psycholog.listRecomendation,
      application: (state) => state.vacancies.application,
      forum: (state) => state.forum.my_forum,
      isVerified: (state) => state.counseling.isVerified,
    }),
    avatar() {
      let data = null;
      if (this.profile) {
        if (this.profile.photo_profile) {
          data = `${this.env}/upload/photo_profile/${this.profile.id}/${this.profile.photo_profile}`;
        } else {
          data = "store";
        }
      }
      return data;
    },
  },
  components: {
    Waiting,
    // EditIntro,
    EditAbout,
    EditService,
    AddEducational,
    AddExperiences,
    UpdateExperiences,
    PreviewCv,
    AddSkills,
    EditEducation,
    CropPhoto,
    FormIntro,
    CheckStatusVerified,
    EditBiro,
  },
  data() {
    return {
      loading: false,
      dialogIntro: false,
      dialogAbout: false,
      dialogEducate: false,
      dialogSkill: false,
      dialogExperiences: false,
      dialogService: false,
      dialogBiro: false,
      editExperiences: false,
      dataExperiences: null,
      editEducation: false,
      dataEducation: null,
      dataCV: null,
      dialogCV: false,
      slide: 0,
      dialogCrop: false,
      tab: null,
      dataAbout: "",
      edited: false,
      updateIntro: false,
      dialogVerified: false,
    };
  },
  mounted() {
    this.fetchEducation();
    this.fetchExperiences();
    this.getRecomendation();
    this.checkReq();
    this.$store.dispatch("feeds/myFeeds");
    this.$store.dispatch("forum/myForum");
    this.$store.dispatch("vacancies/myApps");
  },
  methods: {
    checkReq() {
      this.$store.dispatch("counseling/chekRequest");
    },
    addSkill() {
      this.skills.push(this.someSkill);
      this.someSkill = "";
    },
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("psycholog/myProfile")
        .then((data) => {
          console.log(data);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    fetchEducation() {
      this.$store.dispatch("psycholog/listEducational", this.user_id);
    },

    fetchExperiences() {
      this.$store.dispatch("psycholog/listExperiences", this.user_id);
    },

    getCV() {
      this.dataCV = this.profile;
      this.dialogCV = true;
      console.log(this.dialogCV);
    },
    getRecomendation() {
      this.$store.dispatch("psycholog/getRecomendation", this.user_id);
    },
    upIntro() {
      if (!this.location2) {
        this.location2 = {};
      }
    },

    updateExperiences(item) {
      this.dataExperiences = item;
      this.editExperiences = true;
    },

    updateEducation(item) {
      this.dataEducation = item;
      this.editEducation = true;
    },
    editAbout() {
      this.dataAbout = {
        about: this.profile.profile_singkat,
        skill: this.profile.skill_kompetensi,
      };
      this.dialogAbout = true;
    },

    closeDialog(q) {
      switch (q) {
        case 1:
          this.updateIntro = false;
          break;
        case 2:
          this.dialogAbout = false;
          break;
        case 3:
          this.dialogEducate = false;
          break;
        case 4:
          this.dialogExperiences = false;
          break;
        case 5:
          this.editExperiences = false;
          break;
        case 6:
          this.dialogCV = false;
          break;
        case 7:
          this.dialogSkill = false;
          break;
        case 8:
          this.dialogService = false;
          break;
        case 9:
          this.editEducation = false;
          break;
        case 10:
          this.dialogCrop = false;
          break;
        case 11:
          this.dialogBiro = false;
          break;
      }
    },
  },
};
</script>

<style scoped>
/* div >>> iframe {
  height: 200px;
  width: 100%;
} */
.hover__and__pick {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5 all;
}

.hover__and__pick:hover {
  background: rgb(220, 217, 217);
  transition: 0.5 all;
}
/* .__side {
  margin-top: 113px;
} */
.__profile {
  display: flex;
}
._60-w {
  width: 60%;
}
._10w {
  padding: 0 20px 0 0;
  width: 40%;
}
.min-img {
  margin-top: -120px;
  border: 2px white solid;
}
@media (max-width: 576px) {
  .__side {
    padding: 20px;
    margin-top: 20px;
  }
  .__profile {
    display: block;
  }
  ._60-w {
    width: 100%;
  }
  ._10w {
    width: 100%;
    /* padding-right: 20px; */
  }
}
@media (max-width: 768px) {
  .__side {
    padding: 20px;
    margin-top: 20px;
  }
  .__profile {
    display: block;
  }
  ._60-w {
    width: 100%;
  }
  ._10w {
    width: 100%;
    /* padding-right: 20px; */
  }
}
</style>
