<template>
  <div class="pa-3">
    <h3 class="mb-3 text-center">Edit Intro</h3>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          label="Nama lengkap"
          v-model="profile.nama_lengkap"
          required
          dense
          solo
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- bidang -->
    <v-row>
      <v-col cols="12" md="12" v-if="fields">
        <v-select
          v-if="fields"
          :options="fields.data"
          :reduce="(item) => item.id"
          @search="(query) => (searchFields = query)"
          v-model="profile.bidang_id"
          label="nama"
          placeholder="Bidang"
          class="white"
        ></v-select>
      </v-col>
      <v-col cols="12" md="12" v-if="listBiro">
        <v-select
          v-if="listBiro"
          :options="listBiro"
          :reduce="(item) => item.id"
          @search="(query) => (searchBiro = query)"
          v-model="profile.biro"
          label="nama"
          placeholder="Biro"
          class="white"
        ></v-select>
      </v-col>
    </v-row>
    <!-- no hp -->
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Phone"
          required
          dense
          outlined
          hide-details="auto"
          v-model="profile.no_hp"
        ></v-text-field>
        <!-- <small>Kontak ini akan dilihat oleh publik</small> -->
        <!-- tambahan afif -->
        <v-radio-group v-model="profile.no_hp_show" row>
          <v-radio label="Tampilkan" :value="1"></v-radio>
          <v-radio label="Sembunyikan" :value="0"></v-radio>
        </v-radio-group>
      </v-col>
      <!-- email -->
      <v-col cols="12" md="6">
        <v-text-field
          label="Email"
          required
          dense
          outlined
          hide-details="auto"
          v-model="profile.email"
        ></v-text-field>
        <v-radio-group v-model="profile.email_show" row>
          <v-radio label="Tampilkan" :value="1"></v-radio>
          <v-radio label="Sembunyikan" :value="0"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="12">
        <v-text-field
          label="Website"
          required
          dense
          outlined
          hide-details="auto"
          v-model="profile.website"
        ></v-text-field>
        <v-radio-group v-model="profile.website_show" row>
          <v-radio label="Tampilkan" :value="1"></v-radio>
          <v-radio label="Sembunyikan" :value="0"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          :options="gender"
          :reduce="(item) => item.val"
          v-model="profile.jenis_kelamin"
          label="state"
          placeholder="Gender"
          class="white"
        ></v-select>
      </v-col>
      <!-- aagama -->
      <v-col cols="12" md="6">
        <v-select
          :options="religion"
          :reduce="(item) => item.val"
          v-model="profile.agama"
          @search="(query) => (searchReligion = query)"
          label="state"
          placeholder="Religion"
          class="white"
        ></v-select>
        <small>Terkait kebutuhan klien untuk kasus-kasus tertentu</small>
      </v-col>
    </v-row>
    <div class="my-5">
      <span class="bg pa-1">City</span>
      <v-divider class="mt-n3" inset></v-divider>
    </div>

    <v-row>
      <!-- province -->
      <v-col cols="12" md="6" v-if="listProvince">
        <v-select
          :options="listProvince.data"
          :reduce="(item) => item.id"
          v-model="location1.data_provinsi.id"
          @search="(query) => (searchProvince = query)"
          label="nama_provinsi"
          :appendToBody="true"
          placeholder="Province"
          class="white __pgUp"
          @input="getCity(location1.data_provinsi.id, 'core', 'select')"
        ></v-select>
      </v-col>
      <!-- city 1 -->
      <v-col cols="12" md="6">
        <v-select
          v-if="listCity1"
          :options="listCity1.data"
          :appendToBody="true"
          :reduce="(item) => item.id"
          v-model="location1.id"
          @search="(query) => (searchCity = query)"
          label="nama_kota"
          placeholder="City"
          class="white __pgUp"
        ></v-select>
        <div v-else>
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-1"
          ></v-progress-circular
          >Memuat kota..
        </div>
      </v-col>
    </v-row>
    <!-- addtitional-->
    <div class="my-5">
      <span class="bg pa-1">Additional city</span>
      <v-divider class="mt-n3" inset></v-divider>
    </div>
    <v-row v-if="location2">
      <!-- profince -->
      <v-col cols="12" md="6" v-if="listProvince">
        <v-select
          :options="listProvince.data"
          :reduce="(item) => item.id"
          v-model="location2.data_provinsi.id"
          @search="(query) => (searchProvince = query)"
          label="nama_provinsi"
          placeholder="Province"
          :appendToBody="true"
          class="white"
          @input="getCity(location2.data_provinsi.id, 'add', 'select')"
        ></v-select>
      </v-col>
      <!-- city 2 -->
      <v-col cols="12" md="6">
        <v-select
          v-if="listCity2"
          :options="listCity2.data"
          :reduce="(item) => item.id"
          v-model="location2.id"
          @search="(query) => (searchCity = query)"
          label="nama_kota"
          :appendToBody="true"
          placeholder="City"
          class="white"
        ></v-select>
        <div v-else>
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-1"
          ></v-progress-circular
          >Memuat kota..
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!location2">
      <v-col cols="12" md="6">
        <v-select
          :options="listProvince.data"
          :reduce="(item) => item.id"
          v-model="selectedProvince2"
          @search="(query) => (searchProvince = query)"
          label="nama_provinsi"
          placeholder="Province"
          class="white"
          @input="getCity(selectedProvince2, 'add')"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-if="listCity2"
          :options="listCity2.data"
          :reduce="(item) => item.id"
          v-model="selectedCity2"
          @search="(query) => (searchCity = query)"
          label="nama_kota"
          placeholder="City"
          class="white"
        ></v-select>
        <v-select v-else placeholder="City" class="white">
          <span slot="no-options" @click="$refs.select.open = false">
            pilih provinsi terlebih dahulu
          </span>
        </v-select>
      </v-col>
    </v-row>

    <div class="py-3 mt-3">
      <v-btn block dark color="blue" @click="updateData"><b>Save</b></v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";
export default {
  name: "formIntro",
  components: {
    vSelect,
  },
  props: ["location1", "location2"],
  computed: {
    ...mapState({
      profile: (state) => state.psycholog.myProfile,
      fields: (state) => state.fields.fields,
      listProvince: (state) => state.area.publicProvinces,
      city: (state) => state.area.filteredCity,
      env: (state) => state.API_URL,
    }),
  },
  data() {
    return {
      listBiro: null,
      listCity1: null,
      listCity2: null,
      loadPost: false,
      searchProvince: "",
      selectedProvince2: null,
      selectedCity2: null,
      searchField: "",
      searchBiro: "",
      searchReligion: "",
      religion: [
        { state: "Buddha", val: "buddha" },
        { state: "Hindu", val: "hindu" },
        { state: "Islam", val: "islam" },
        { state: "Katolik", val: "katolik" },
        { state: "Konghucu", val: "konghucu" },
        { state: "Protestan", val: "protestan" },
        { state: "Lainya", val: "lainya" },
      ],
      gender: [
        { state: "Laki-laki", val: "Laki-laki" },
        { state: "Perempuan", val: "Perempuan" },
      ],
    };
  },

  mounted() {
    this.$store.dispatch("fields/listFields");
    this.$store.dispatch("area/publicProvince").then(() => {
      this.getCity(this.location1.data_provinsi.id, "core");
      this.getCity(this.location2.data_provinsi.id, "add");
    });
    this.getBiro();
  },

  methods: {
    getBiro() {
      //get all data campus fucntion
      this.loading = true;
      let data = {
        path: `public/biro`,
      };
      this.$store.dispatch("getApi", data).then((data) => {
        this.listBiro = data.data;
      });
    },
    getCity(id, type, from) {
      if (type == "core") {
        this.$store.dispatch("area/filterCity", id).then((data) => {
          this.listCity1 = data;
          if (from) {
            this.location1.id = data.data[0].id;
          }
        });
      } else {
        this.$store.dispatch("area/filterCity", id).then((data) => {
          this.listCity2 = data;
          if (from) {
            this.location2.id = data.data[0].id;
          }
        });
      }
    },

    updateData() {
      this.loadPost = true;
      let data = {
        nama_lengkap: this.profile.nama_lengkap,
        bidang_id: this.profile.bidang_id,
        email: this.profile.email,
        no_hp: this.profile.no_hp,
        agama: this.profile.agama,
        biro: this.profile.biro,
        jenis_kelamin: this.profile.jenis_kelamin,
        kota1_id: this.location1.id,
        skill_kompetensi: this.profile.skill_kompetensi,
        kota2_id: this.location2 ? this.location2.id : this.selectedCity2,
        website: this.profile.website,
        no_hp_show: this.profile.no_hp_show,
        website_show: this.profile.website_show,
        email_show: this.profile.email_show,
      };

      let id = this.profile.id;
      this.$store
        .dispatch("psycholog/updateProfile", { id: id, data: data })
        .then((data) => {
          console.log(data);
          this.$emit("refetch");
          this.$emit("close");
          this.loadPost = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        })
        .catch((err) => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loadPost = false;
        });
    },

    changePicture(e) {
      const data = new FormData();
      this.loadPost = true;
      let pic = e.target.files[0];
      data.append("objPhoto", pic);
      this.$store
        .dispatch("changePic", data)
        .then((data) => {
          console.log(data.message);
          this.loadPost = false;
          this.$emit("refetch");
          this.alert("success", data.message);
        })
        .catch((err) => {
          console.log(err);
          this.loadPost = false;
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
        });
    },
    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000,
      });
    },
  },
};
</script>

<style></style>
