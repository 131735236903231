<template>
  <v-row justify="start">
    <v-dialog v-model="dialogEducate" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">History Educational</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2">
          <v-container>
            <!-- nama sekolah -->
            <v-row>
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Nama Universitas</p>
                <v-text-field
                  placeholder="Ketik disini"
                  v-model="nama_sekolah"
                  required
                  dense
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- jenjang -->
            <v-row>
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Jenjang Pendidikan</p>
                <v-select
                  v-model="jenjang"
                  :items="level"
                  item-text="state"
                  item-value="abbr"
                  placeholder="Pilih disini"
                  solo
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <!-- tahun lulus -->
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Tahun Lulus</p>
                <v-select
                  v-model="gradeYear"
                  :items="getYear"
                  item-text="name"
                  item-value="abbr"
                  placeholder="Pilih disini"
                  solo
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <Waiting v-if="loading" />
            <notifications
              group="validate"
              class="mt-2"
              position="top center"
            />
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="addData" v-if="!loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Waiting from "../../../_base/Modal/waiting.vue";
import Swal from "sweetalert2";

export default {
  name: "addEducate",

  props: ["dialogEducate"],
  components: {
    Waiting
  },
  computed: {
    ...mapState({
      env: state => state.API_URL,
      id: state => state.id
    }),
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = year; i >= 1980; i--) {
        data.push({
          name: i,
          idx: i
        });
      }
      return data;
    }
  },
  data() {
    return {
      nama_sekolah: "",
      gradeYear: null,
      jenjang: "",
      loading: false,
      level: [
        { state: "S1", abbr: "S1" },
        { state: "S2", abbr: "S2" },
        { state: "S3", abbr: "S3" }
      ]
    };
  },

  methods: {
    addData() {
      let data = new FormData();
      this.loading = true;
      data.append("user_id", this.id);
      data.append("tingkat", this.jenjang);
      data.append("nama_lembaga_pendidikan", this.nama_sekolah);
      data.append("tahun_lulus", this.gradeYear);

      this.$store
        .dispatch("psycholog/createEducational", data)
        .then(data => {
          this.jenjang = "";
          this.nama_sekolah = "";
          this.gradeYear = null;
          this.$emit("refetch");
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loading = false;
        });
    },

    changePicture(e) {
      const data = new FormData();
      this.loading = true;
      let pic = e.target.files[0];
      data.append("objPhoto", pic);
      this.$store.dispatch("changePic", data).then(data => {
        console.log(data.message);
        this.loading = false;
        this.$emit("refetch");
        this.alert("success", data.message);
      });
    },
    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
}
.bg {
  background: #ffff;
}
</style>
