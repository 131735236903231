<template>
  <v-row justify="start">
    <v-dialog v-model="editEducation" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">History Educational</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2" v-if="dataEducation">
          <v-container>
            <!-- nama sekolah -->
            <v-row>
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Nama Universitas</p>
                <v-text-field
                  placeholder="Ketik disini"
                  v-model="dataEducation.namaLembagaPendidikan"
                  required
                  dense
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- jenjang -->
            <v-row>
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Jenjang Pendidikan</p>
                <v-select
                  v-model="dataEducation.tingkat"
                  :items="level"
                  item-text="state"
                  item-value="abbr"
                  placeholder="Pilih dsisini"
                  solo
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <!-- tahun lulus -->
              <v-col cols="12" md="12">
                <p class="small_txt color_txt ma-0">Tahun Lulus</p>
                <v-select
                  v-model="dataEducation.tahunLulus"
                  :items="getYear"
                  item-text="name"
                  item-value="abbr"
                  placeholder="Pilih disini"
                  solo
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <Waiting v-if="loading" />
            <notifications
              group="validate"
              class="mt-2"
              position="top center"
            />
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            rounded
            @click="deleteData(dataEducation.id)"
            v-if="!loading"
            >delete</v-btn
          >
          <v-btn
            color="blue"
            outlined
            rounded
            @click="updateData"
            v-if="!loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Waiting from "../../../_base/Modal/waiting.vue";
import Swal from "sweetalert2";

export default {
  name: "editEducate",

  props: ["editEducation", "dataEducation"],
  components: {
    Waiting
  },
  computed: {
    ...mapState({
      env: state => state.API_URL
    }),
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = year; i >= 1980; i--) {
        data.push({
          name: i,
          idx: i
        });
      }
      return data;
    }
  },
  data() {
    return {
      loading: false,
      level: [
        { state: "S1", abbr: "S1" },
        { state: "S2", abbr: "S2" },
        { state: "S3", abbr: "S3" }
      ]
    };
  },

  methods: {
    updateData() {
      let data = new FormData();
      this.loading = true;
      data.append(
        "nama_lembaga_pendidikan",
        this.dataEducation.namaLembagaPendidikan
      );
      data.append("tingkat", this.dataEducation.tingkat);
      data.append("tahun_lulus", this.dataEducation.tahunLulus);
      let params = {
        id: this.dataEducation.id,
        data: data
      };
      this.$store
        .dispatch("psycholog/updateEducational", params)
        .then(data => {
          console.log(data);
          this.$emit("refetch");
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          this.loading = false;
          this.alert("error", `${err.message}`);
        });
    },

    deleteData(id) {
      this.$store
        .dispatch("psycholog/deleteEducational", { id: id })
        .then(data => {
          this.$emit("refetch");
          this.$emit("close");
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        });
    },

    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
}
.bg {
  background: #ffff;
}
</style>
