<template>
  <v-row justify="start">
    <v-dialog v-model="dialogBiro" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Biro</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2" v-if="profile">
          <v-container>
            <v-text-field
              label="Name Biro"
              outlined
              required
              dense
              hide-details="auto"
              v-model="profile.biro"
            ></v-text-field>
            <v-text-field
              class="mt-3"
              label="Address Biro"
              outlined
              required
              dense
              hide-details="auto"
              v-model="profile.biro_alamat"
            ></v-text-field>
          </v-container>
        </v-card-text>
        <Waiting v-if="loading" />
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateData">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Waiting from "../../../_base/Modal/waiting.vue";

export default {
  name: "editBiro",

  props: ["dialogBiro"],
  components: { Waiting },
  computed: {
    ...mapState({
      profile: (state) => state.psycholog.myProfile,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },

  mounted() {
    console.log(this.profile);
    this.$store.dispatch("fields/listFields");
  },

  methods: {
    updateData() {
      let data = new FormData();
      this.loading = true;
      data.append("biro", this.profile.biro);
      data.append("biro_alamat", this.profile.biro_alamat);
      let id = this.profile.id;
      this.$store
        .dispatch("psycholog/updateProfile", { id: id, data: data })
        .then((data) => {
          console.log(data);
          this.$emit("refetch");
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        })
        .catch((err) => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000,
      });
    },
  },
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px black dotted;
}
.bg {
  background: #ffff;
}
</style>
