<template>
  <v-row justify="start">
    <v-dialog v-model="dialogSkill" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Skills and compentency</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2 py-0" v-if="profile">
          <v-container>
            <div v-if="profile.skill_kompetensi" class="d-flex flex-wrap mb-3">
              <div
                v-for="(item, idx) in profile.skill_kompetensi"
                :key="`skills-ke-${idx}`"
              >
                <v-chip
                  class="ma-2"
                  close
                  @click:close="removeSkill(item)"
                  color="orange"
                  label
                  outlined
                >
                  {{ item }}
                </v-chip>
              </div>
            </div>
            <form @submit.prevent="addSkill">
              <v-text-field
                label="Skills"
                v-model="someSkill"
                dense
              ></v-text-field>
              <div>
                <v-btn type="addSkill" outlined rounded color="green"
                  >add</v-btn
                >
              </div>
            </form>
          </v-container>
        </v-card-text>
        <Waiting v-if="loading" />
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateData">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Waiting from "../../../_base/Modal/waiting.vue";

export default {
  name: "skillcompeten",

  props: ["dialogSkill"],
  components: { Waiting },
  computed: {
    ...mapState({
      profile: state => state.psycholog.myProfile
    })
  },
  data() {
    return {
      loading: false,
      someSkill: "",
      skills: []
    };
  },

  mounted() {
    this.$store.dispatch("fields/listFields");
  },

  methods: {
    addSkill() {
      if (this.someSkill !== "") {
        if (this.profile.skill_kompetensi) {
          this.profile.skill_kompetensi.push(this.someSkill);
        } else {
          this.profile.skill_kompetensi = [];
          this.profile.skill_kompetensi.push(this.someSkill);
        }
        this.someSkill = "";
      }
    },
    removeSkill(item) {
      let arr = this.profile.skill_kompetensi;
      let index = arr.indexOf(item);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },

    updateData() {
      this.loading = true;

      let data = {
        skill_kompetensi: this.profile.skill_kompetensi
      };
      let id = this.profile.id;

      this.$store
        .dispatch("psycholog/updateProfile", { id: id, data: data })
        .then(data => {
          console.log(data);
          this.$emit("refetch");
          this.$emit("close");
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loading = false;
        });
    },

    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px black dotted;
}
.bg {
  background: #ffff;
}
</style>
