<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAbout" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">About</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2">
          <v-container>
            <v-textarea
              label="Tulis ringkasan"
              :error-messages="errMsg"
              solo
              counter
              v-model="dataAbout.about"
              @input="$v.about.$touch()"
              @blur="$v.about.$touch()"
            ></v-textarea>
          </v-container>
        </v-card-text>
        <Waiting v-if="loading" />
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateData">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import Waiting from "../../../_base/Modal/waiting.vue";
import { validationMixin } from "vuelidate";
import { maxLength } from "vuelidate/lib/validators";

export default {
  name: "editAbout",
  mixins: [validationMixin],
  validations: {
    about: { maxLength: maxLength(225) }
  },
  props: ["dialogAbout", "dataAbout"],
  components: { Waiting },
  computed: {
    ...mapState({
      id: state => state.id
    }),
    errMsg() {
      const errors = [];
      if (!this.$v.about.$dirty) return errors;
      !this.$v.about.maxLength && errors.push("Text maksimal 225 karakter!");
      return errors;
    }
  },
  data() {
    return {
      loading: false,
      about: ""
    };
  },

  mounted() {
    this.$store.dispatch("fields/listFields");
  },

  methods: {
    updateData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        // let data = new FormData();
        // console.log(this.dataAbout.skill);
        // data.append("profile_singkat", this.dataAbout.about);
        // data.append("skill_kompetensi", []);
        let data = {
          profile_singkat: this.dataAbout.about,
          skill_kompetensi: this.dataAbout.skill
        };
        let id = this.id;
        this.$store
          .dispatch("psycholog/updateProfile", { id: id, data: data })
          .then(data => {
            this.$emit("refetch");
            this.$emit("close");
            this.loading = false;
            Swal.fire({
              icon: "success",
              title: data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          })
          .catch(err => {
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
            this.loading = false;
          });
      }
    },

    alert(type, title) {
      this.$notify({
        group: "validate",
        type: type,
        title: title,
        duration: 5000
      });
    }
  }
};
</script>

<style scoped>
.add-width {
  width: 170px;
}
.temp {
  position: relative;
}
.over {
  position: absolute;
  margin-top: -5px;
  margin-right: -5px;
}
.img-temp__ {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px black dotted;
}
.bg {
  background: #ffff;
}
</style>
