<template>
  <v-row justify="start">
    <v-dialog v-model="dialogVerified" persistent max-width="450px">
      <v-card class="radius-card hide_overflow">
        <v-toolbar class="bg_none" dense flat>
          <p class="small_txt ma-0">
            <b> Request menggunakan menu konseling</b>
          </p>
          <v-spacer></v-spacer>
          <v-btn fab x-small color="white" depressed @click="$emit('close')"
            ><v-icon small color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <div>
          <div class="px-5 mt-5 mb-10">
            <v-row>
              <v-col cols="12">
                <p class="small_txt color_txt ma-0">No Rekening</p>
                <v-text-field
                  placeholder="Ketik Disini"
                  v-model="norek"
                  required
                  dense
                  type="number"
                  hide-details
                  color="purple"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt color_txt ma-0">Nama Akun</p>
                <v-text-field
                  placeholder="Ketik Disini"
                  v-model="account_name"
                  required
                  dense
                  hide-details
                  color="purple"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt color_txt ma-0">Nama Bank</p>
                <v-text-field
                  placeholder="Ketik Disini"
                  v-model="bank_name"
                  required
                  dense
                  hide-details
                  color="purple"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt color_txt ma-0">No Hp</p>
                <v-text-field
                  placeholder="Ketik Disini"
                  v-model="no_hp"
                  required
                  dense
                  type="number"
                  hide-details
                  color="purple"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="pa-3">
            <v-btn
              class="py-7 text-capitalize"
              @click="sendReq"
              depressed
              block
              dark
              color="purple"
              ><b>Kirim</b></v-btn
            >
          </div>
        </div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "alertQuiz",
  props: ["dialogVerified", "result"],

  data() {
    return {
      role: "",
      norek: "",
      account_name: "",
      bank_name: "",
      no_hp: "",
      loading: false
    };
  },
  methods: {
    sendReq() {
      this.loading = true;
      let data = {
        requirement: {
          no_rek: this.norek,
          account_name: this.account_name,
          bank_name: this.bank_name,
          no_hp: this.no_hp
        }
      };

      this.$store
        .dispatch("counseling/requestCounseling", data)
        .then(() => {
          this.$emit("close");
          this.$emit("checking");
          Swal.fire({
            icon: "success",
            title: "Request berhasil di kirim",
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Request gagal!",
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        });
    }
  }
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
